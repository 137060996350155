const en_US = {
  Lending: 'Lending',
  Tokenization: 'Tokenization',
  Staking: 'Staking',
  Documents: 'Documents',
  Liqee: 'Liqee',
  Liqee_subTitle: 'A lending protocol for staking yield tokens and a unified portal for liquid staking markets.',
  Launch_APP: 'Launch APP',
  TotalSupply: 'Total Supply',
  TotalBorrow: 'Total Borrow',
  TotalMint: 'Total Tokenized Assets',
  TotalStaked: 'Total PoS Staking',
  How_to_Use_Liqee:'How to Use Liqee',
  Lending_text:'Decentralized lending market (powered by dForce) for liquid staking assets.',
  Tokenization_text:'Tokenize staking assets or mining assets into yield tokens with 7/24 liquidity and no compromise on staking yield.',
  Staking_text:'Delegate your PoS staking tokens to Liqee validators offering the best rates.',
  Lend_with_Liqee:'Lend with Liqee',
  PoS_Staking_Yield:'PoS Staking Yield',
  Liquidity:'7/24 Liquidity',
  Decentralization:'Decentralization',
  Permission_less:'Permission-lessness',
  Non_Custodial:'Non-Custody',
  Transparency:'Transparency',
  Cost_Efficiency:'Cost-Efficiency',
  Composability:'Composability',
  Tokenize_with_Liqee:'Tokenize with Liqee',
  Tokenize_with_Liqee_text1:'Liqee helps miners / validators to tokenize their staking assets, unlock liquidity and improve capital efficiency. tToken is a tokenized representation of bonded PoS assets, staking assets and mining assets.',
  Our_current:'Current available tAssets on Liqee are: ',
  Our_current_text1:' tFil, pledged node ',
  Our_current_text2:' tXTZ, pledged node ',
  here:'here',
  Tokenize_with_Liqee_text2:"If you are interested in tokenizing your staking assets, please reach out to us via ",
  APR:'APR',
  Mint:'Mint',
  Stake_with_Liqee:'Stake with Liqee',
  COSMOS:'COSMOS',
  TEZOS:'TEZOS',
  ETHEREUM2:'ETHEREUM2.0',
  Polkadot:'POLKADOT',
  Kusama:'KUSAMA',
  Osmosis:'OSMOSIS',
  in_development:'Network is in development',
  is_live:'Network is live',
  coming_soon:'coming soon...',
  APY:'APY',
  steps:'steps:',
  DAO_Governance:'DAO Governance',
  DAO_Governance_text:'LQE is the governance token of Liqee protocol, where holders can jointly vote on proposals shaping the future of Liqee.',
  Security_Audits:'Security Audits',
  Security_Audits_text:'The Liqee lending markets codebase is based on codes from dForce lending.',
  Ecosystem:'Ecosystem',
  Ecosystem1:'dForce advocates for building an integrated and interoperable DeFi protocol matrix, covering lending (global pool, yield markets), assets (multi-currency stablecoin, synths, etc), and trading (aggregator).',
  Ecosystem2:'StaFi is the first DeFi protocol unlocking liquidity of staked assets. Users can stake PoS tokens through StaFi and receive rTokens in return, which are available for trading while still earning staking rewards.',
  Ecosystem3:'Lido is a liquid staking solution for Ethereum. Lido allows users to stake their ETH without locking assets while maintaining infrastructure and participating in on-chain activities like lending.',
  Ecosystem4:'Curve is a popular AMM platform that offers a highly efficient way to exchange tokens while maintaining low fees and low slippage by only accommodating liquidity pools made up of similarly behaving assets.',
  Product:'Product',
  Doc:'Documents',
  FAQ:'FAQ',
  Developers:'Developers',
  Audit_Reports:'Audit Reports',
  Whitepaper:'Whitepaper',
  Tokennomics:'Tokennomics',
  Governance:'Governance',
  Forum:'Forum',
  Vote:'Vote',
  Community:'Community',
  Twitter:'Twitter',
  Medium:'Medium',
  Telegram:'Telegram',
  WeChat:'WeChat'

};
export default en_US;
