const zh_CN = {
  Lending:'借贷',
  Tokenization:'资产代币化',
  Staking:'质押',
  Documents:'文档',
  Liqee:'Liqee',
  Liqee_subTitle:'专注于质押生息资产的DeFi借贷，为质押生息市场提供一体化服务。',
  Launch_APP:'使用APP',
  TotalSupply:'存款总额',
  TotalBorrow:'借款总额',
  TotalMint: '资产代币化总额',
  TotalStaked: 'PoS资产委托总额',
  How_to_Use_Liqee:'如何使用Liqee',
  Lending_text:'为质押资产提供高流动性和跨链服务的DeFi借贷市场（由dForce提供支持）。',
  Tokenization_text:'将质押资产或算力资产代币化，获得质押生息代币。用户将同时享有7/24的流动性和底层资产的质押利息。',
  Staking_text:'委托Liqee进行PoS资产质押，更低费用，更高收益。',
  Lend_with_Liqee:'Liqee借贷',
  PoS_Staking_Yield:'PoS质押利息',
  Liquidity:'7/24流动性',
  Decentralization:'去中心化',
  Permission_less:'无需授权',
  Non_Custodial:'无需托管',
  Transparency:'高度透明',
  Cost_Efficiency:'高资本效率',
  Composability:'可组合性',
  Tokenize_with_Liqee:'质押资产代币化',
  Tokenize_with_Liqee_text1:'Liqee为矿工和节点服务商提供质押资产的代币化服务，从而提升资产流动性及资金效率。tToken是一种PoS资产、质押资产、矿机/算力资产代币化的凭证。',
  Our_current:'目前Liqee提供的代币化资产包括：',
  Our_current_text1:' tFIL - 质押节点',
  Our_current_text2:' tXTZ - 质押节点',
  here:'查看',
  Tokenize_with_Liqee_text2:"如您需要质押资产代币化服务，请通过邮件与我们联系：",
  APR:'年化利率',
  Mint:'铸造',
  Stake_with_Liqee:'Liqee质押',
  COSMOS:'COSMOS',
  TEZOS:'TEZOS',
  ETHEREUM2:'以太坊2.0',
  Polkadot:'波卡',
  Kusama:'KUSAMA',
  Osmosis:'OSMOSIS',
  in_development:'节点准备中',
  is_live:'节点运行中',
  coming_soon:'即将上线',
  APY:'年化收益',
  steps:'操作步骤：',
  DAO_Governance:'DAO治理',
  DAO_Governance_text:'LQE是Liqee的治理代币，持有人可以通过投票的方式，共同决定Liqee的未来发展。',
  Security_Audits:'安全审计',
  Security_Audits_text:'Liqee借贷市场的底层代码由dForce提供技术支持。',
  Ecosystem:'生态网络',
  Ecosystem1:'dForce致力于构建一个集成的和互通的开放式金融协议和货币协议矩阵，包括借贷（全球流动性池、生息市场）、资产（多货币稳定币、合成资产等）、交易（聚合器）三大类。',
  Ecosystem2:'StaFi是首个释放质押资产流动性的DeFi协议。用户可以通过StaFi进行PoS资产的质押并获得rTokens——同时具备质押利息和流动性的质押生息代币。',
  Ecosystem3:'Lido是以太坊质押资产流动性的解决方案。通过Lido质押ETH，用户无需锁定资产便可以参与ETH 2.0底层设施的维护，同时参与其它链上活动（如DeFi借贷）。',
  Ecosystem4:'Curve - 知名自动做市商（AMM）平台，通过流动性池实现价格相似的同质资产之间的高效兑换，低手续费、低滑点。',
  Product:'产品',
  Doc:'文档',
  FAQ:'常见问题',
  Developers:'开发者',
  Audit_Reports:'审计报告',
  Whitepaper:'白皮书',
  Tokennomics:'代币经济', 
  Governance:'治理',
  Forum:'论坛',
  Vote:'投票',
  Community:'社区',
  Twitter:'Twitter',
  Medium:'Medium',
  Telegram:'电报群',
  WeChat:'微信'
};
export default zh_CN;