/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback,useEffect,createRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
const Home = ({ changeLanguage, locale }) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const ref = createRef(null)
  const [open, setOpen] = useState(false);
  const [totalData, setTotalData] = useState({})
  const [FIL, setFIL] = useState(0)
  const [tXTZ, settXTZ] = useState(0)
  const showList = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const [menu, setMenu] = useState(false);
  const showMenu = useCallback(() => {
    setMenu(!menu);
  }, [menu]);
  const [stake, setStake] = useState(0);
  const showStakeModel = useCallback((e) => {
      console.log(e.currentTarget)
      console.log(e.currentTarget.dataset.id)
      const id = e.currentTarget.dataset.id ? e.currentTarget.dataset.id : 0
        setStake(id);
  }, []);
  useEffect(()=>{
    try {
      fetch(`/api/getValue/`, { method: 'get' })
        .then(e => e.json())
        .then(function (response) {
          let resArr = Object.entries(response.TVL)
          const pop = resArr.pop()
          resArr.unshift(pop)
          resArr = Object.fromEntries(resArr);
          setTotalData(resArr)
          const {tFil,tXTZ} = response.tToken
          setFIL(tFil)
          settXTZ(tXTZ)
        })
        .catch(function (err) {
          console.log(err)
        })
    } catch (err) {
      console.log(err)
    }
  },[])
  // useEffect(() => {
  //   window.document.body.addEventListener("click", (e) => {
  //     console.log(ref.current)
  //     if (!ref.current?.contains(e.target)) {
  //       return;
  //     }
  //     setOpen(false);
  //   });
  //   return () => {
  //     window.document.body.removeEventListener("click", (e)=>{
  //       e.preventDefault();
  //   }, false);
  //   };
  // }, [ref,open]);
  return (
    <>
      <Container>
        <Header>
          <TopBar>
            <Log src="/images/logo.svg" />
            <Nav>
              {/* <li>
                <a href="#" rel="noreferrer">{fmt({ id: 'Lending' })}</a>
              </li> */}
              {/* <li>
              <a href="#" target='_blank'>{fmt({ id: 'Tokenization' })}</a>
              </li>
              <li>
              <a href="#" target='_blank'>{fmt({ id: 'Staking' })}</a>
              </li> */}
              <li>
              <a href="https://docs.liqee.io/" target="_blank" rel="noreferrer">{fmt({ id: 'Documents' })}</a>
              </li>
              <ChangeLanguage onClick={showList}>
                <img
                  src={locale === 'en' ? '/images/EN.svg' : '/images/CN.svg'}
                />
                <span>{locale === 'en' ? 'English' : '简体中文'}</span>
                <b></b>
                <Changelist ref={ref} className={open ? '' : 'hide'}>
                  <li onClick={() => changeLanguage('en')}>
                    <img src="/images/EN.svg" />
                    <span>English</span>
                  </li>
                  <li onClick={() => changeLanguage('cn')}>
                    <img src="/images/CN.svg" />
                    <span>简体中文</span>
                  </li>
                </Changelist>
              </ChangeLanguage>
            </Nav>
          </TopBar>
          <Slogan>
            <SloLeft>
              <h1>{fmt({ id: 'Liqee' })}</h1>
              <h3>{fmt({ id: 'Liqee_subTitle' })}</h3>
              <a href="https://app.liqee.io" rel="noreferrer">
                {fmt({ id: 'Launch_APP' })}
                <img src="/images/launch-arrive.svg" />
              </a>
            </SloLeft>
            <SloDate>
              {
                (Object.keys(totalData).length > 0) ? 
                Object.keys(totalData).map(item =>(
                  <Item>
                    <h6>{fmt({ id: `${item}` })}</h6>
                    <p>${Number(totalData[`${item}`]).toLocaleString(undefined,{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </p>
                  </Item>
                ))
                :
                <>
                  <Item>
                    <h6>{fmt({ id: 'TotalSupply' })}</h6>
                    <p>...</p>
                  </Item>
                  <Item>
                    <h6>{fmt({ id: 'TotalBorrow' })}</h6>
                    <p>...</p>
                  </Item>
                  <Item>
                    <h6>{fmt({ id: 'TotalMint' })}</h6>
                    <p>...</p>
                  </Item>
                  <Item>
                    <h6>{fmt({ id: 'TotalStaked' })}</h6>
                    <p>...</p>
                  </Item>
                </>
              }
            </SloDate>
          </Slogan>
        </Header>
        <Mheader>
          <MtopBar>
            <Log src="/images/logo-m-hide.svg" />
            <Menu src="/images/m-menu.svg" onClick={showMenu} />
          </MtopBar>
          <Slogan>
            <SloLeft>
              <h1>{fmt({ id: 'Liqee' })}</h1>
              <h3>{fmt({ id: 'Liqee_subTitle' })}</h3>
              <a href="https://app.liqee.io" rel="noreferrer">
                {fmt({ id: 'Launch_APP' })}
                <img src="/images/launch-arrive.svg" />
              </a>
            </SloLeft>
            <SloDate>
            {
                (Object.keys(totalData).length > 0) ? 
                Object.keys(totalData).map(item =>(
                  <Item>
                    <h6>{fmt({ id: `${item}` })}</h6>
                    <p>${Number(totalData[`${item}`]).toLocaleString(undefined,{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </p>
                  </Item>
                ))
                :
                <>
                  <Item>
                    <h6>{fmt({ id: 'TotalSupply' })}</h6>
                    <p>...</p>
                  </Item>
                  <Item>
                    <h6>{fmt({ id: 'TotalBorrow' })}</h6>
                    <p>...</p>
                  </Item>
                  <Item>
                    <h6>{fmt({ id: 'TotalMint' })}</h6>
                    <p>...</p>
                  </Item>
                  <Item>
                    <h6>{fmt({ id: 'TotalStaked' })}</h6>
                    <p>...</p>
                  </Item>
                </>
              }
            </SloDate>
          </Slogan>
        </Mheader>
        <Plate>
          <Title>{fmt({ id: 'How_to_Use_Liqee' })}</Title>
          <PlateList>
            <HowItem>
              <img src="/images/howuse1.svg" />
              <h6>{fmt({ id: 'Lending' })}</h6>
              <p>
              {fmt({ id: 'Lending_text' })}
              </p>
              <a href="https://app.liqee.io" />
            </HowItem>
            <HowItem>
              <img src="/images/howuse2.svg" />
              <h6>{fmt({ id: 'Tokenization' })}</h6>
              <p>
              {fmt({ id: 'Tokenization_text' })}
              </p>
              <a className="hide" />
            </HowItem>
            <HowItem>
              <img src="/images/howuse3.svg" />
              <h6>{fmt({ id: 'Staking' })}</h6>
              <p>
              {fmt({ id: 'Staking_text' })}
              </p>
              <a className="hide" />
            </HowItem>
          </PlateList>
        </Plate>
        <Plate>
          <Title>{fmt({ id: 'Lend_with_Liqee' })}</Title>
          <PlateList>
            <LendItem>
              <img src="/images/PoS_Staking_Yield.svg" />
              <p>{fmt({ id: 'PoS_Staking_Yield' })}</p>
            </LendItem>
            <LendItem>
              <img src="/images/Liquidity.svg" />
              <p>{fmt({ id: 'Liquidity' })}</p>
            </LendItem>
            <LendItem>
              <img src="/images/Decentralization.svg" />
              <p>{fmt({ id: 'Decentralization' })}</p>
            </LendItem>
            <LendItem>
              <img src="/images/Permission_less.svg" />
              <p>{fmt({ id: 'Permission_less' })}</p>
            </LendItem>
            <LendItem>
              <img src="/images/Non_Custodial.svg" />
              <p>{fmt({ id: 'Non_Custodial' })}</p>
            </LendItem>
            <LendItem>
              <img src="/images/Transparency.svg" />
              <p>{fmt({ id: 'Transparency' })}</p>
            </LendItem>
            <LendItem>
              <img src="/images/Cost_Efficiency.svg" />
              <p>{fmt({ id: 'Cost_Efficiency' })}</p>
            </LendItem>
            <LendItem>
              <img src="/images/Composability.svg" />
              <p>{fmt({ id: 'Composability' })}</p>
            </LendItem>
          </PlateList>
        </Plate>
        <Plate>
          <Title>{fmt({ id: 'Tokenize_with_Liqee' })}</Title>
          <PlateList>
            <TokenizeItem>
              <img src="/images/Liquid.png" />
            </TokenizeItem>
            <TokenizeItem>
              <dl>
                <dd>
                {fmt({ id: 'Tokenize_with_Liqee_text1' })}
                </dd>
                <dt>
                  <h2>{fmt({ id: 'Our_current' })}</h2>
                  <ul>
                    <li>{Number(FIL).toLocaleString(undefined,{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{fmt({ id: 'Our_current_text1' })}<a href="https://filfox.info/zh/address/f0829836" target="_blank" rel="noopener noreferrer">{fmt({ id: 'here' })}</a></li>
                    <li>{Number(tXTZ).toLocaleString(undefined,{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{fmt({ id: 'Our_current_text2' })}<a href="https://tzstats.com/tz1LmaFsWRkjr7QMCx5PtV6xTUz3AmEpKQiF" target="_blank" rel="noopener noreferrer">{fmt({ id: 'here' })}</a></li>
                  </ul>
                </dt>
                <dd>{fmt({ id: 'Tokenize_with_Liqee_text2' })}<a href="mailto:bd@liqee.io" rel="noopener noreferrer">bd@liqee.io</a></dd>
              </dl>
            </TokenizeItem>
          </PlateList>
        </Plate>
        <Plate>
          <Title>{fmt({ id: 'Stake_with_Liqee' })}</Title>
          <PlateList className={stake ? 'hide' : ''}>
            <StakeItem className="disabled" onClick={showStakeModel} data-id='1'>
              <img src="/images/ATOM.svg" />
              <p>{fmt({ id: 'COSMOS' })}</p>
              {/* <Status>{fmt({ id: 'in_development' })}</Status> */}
              <span>{fmt({ id: 'coming_soon' })}</span>
            </StakeItem>
            <StakeItem className="disabled" onClick={showStakeModel} data-id='2'>
              <img src="/images/Tezos.svg" />
              <p>{fmt({ id: 'TEZOS' })}</p>
              {/* <Status>{fmt({ id: 'in_development' })}</Status> */}
              <span>{fmt({ id: 'coming_soon' })}</span>
            </StakeItem>
            <StakeItem className="disabled" onClick={showStakeModel} data-id='3'>
              <img src="/images/ETH.svg" />
              <p>{fmt({ id: 'ETHEREUM2' })}</p>
              {/* <Status>{fmt({ id: 'in_development' })}</Status> */}
              <span>{fmt({ id: 'coming_soon' })}</span>
            </StakeItem>
            {/* <StakeItem className="disabled">
              <img src="/images/ETH.svg" />
              <p>{fmt({ id: 'Polkadot' })}</p>
              <Status>Network is in development</Status>
              <span>{fmt({ id: 'coming_soon' })}</span>
            </StakeItem>
            <StakeItem className="disabled">
              <img src="/images/ETH.svg" />
              <p>{fmt({ id: 'Kusama' })}</p>
              <Status>Network is in development</Status>
              <span>{fmt({ id: 'coming_soon' })}</span>
            </StakeItem>
            <StakeItem className="disabled">
              <img src="/images/ETH.svg" />
              <p>{fmt({ id: 'Osmosis' })}</p>
              <Status>Network is in development</Status>
              <span>{fmt({ id: 'coming_soon' })}</span>
            </StakeItem> */}
          </PlateList>
          <StakeModel className={stake === '1' ? 'show' : ''}>
            <Back onClick={showStakeModel}>Back</Back>
            <StakeWrap>
              <StakeTop>
                <Lsec>
                  <Logo src="/images/ATOM.svg" />
                  <Currency>
                    <Symbol>ATOM</Symbol>
                    <Chain>COSMOS</Chain>
                  </Currency>
                </Lsec>
                <Block>
                <DataApy>7.00%</DataApy>
                  <Tit>Apy</Tit>
                </Block>
                <Block>
                  <Data>3.00%</Data>
                  <Tit>Commission</Tit>
                </Block>
                <Status>Network is live</Status>
              </StakeTop>
              <Line />
              <StakeCenter>
                <ProfitList>
                  <Profit>年收益：tokenNum * APY * (1-COMMISSION)</Profit>
                  <Profit>月收益：tokenNum * APY * (1-COMMISSION) / 12</Profit>
                  <Profit>日收益：tokenNum * APY * (1-COMMISSION)/ 365</Profit>
                </ProfitList>
                <ProArrow />
                <Example>
                  如果Stake 10,000 Token，那么年收入如下计算： <br/>年收益=10000 *
                  7.00% * (1-3.00%)=679 Token
                </Example>
              </StakeCenter>
              <Line />
              <StakeBottom>
                <h1>{fmt({id:'steps'})}</h1>
                <p>
                  1. 点击下方链接进入网页端
                  <br />
                  <a href="https://wallet.keplr.app/#/cosmoshub/stake?modal=detail&validator=cosmosvaloper1rpgtz9pskr5geavkjz02caqmeep7cwwpv73axj">
                    https://wallet.keplr.app/#/cosmoshub/stake?modal=detail&validator=cosmosvaloper1rpgtz9pskr5geavkjz02caqmeep7cwwpv73axj
                  </a>
                </p>
                <p>
                  2.CLI
                  <br />
                  <code>gaiad tx staking delegate
                  cosmosvaloper1rpgtz9pskr5geavkjz02caqmeep7cwwpv73axj
                  &lt;amountToBond&gt; --from &lt;delegatorKeyName&gt; --gas
                  auto --gas-adjustment 1.5 --gas-prices 0.025uatom</code>
                </p>
              </StakeBottom>
            </StakeWrap>
          </StakeModel>
          <StakeModel className={stake === '2' ? 'show' : ''}>
            <Back onClick={showStakeModel}>Back</Back>
            <StakeWrap>
              <StakeTop>
                <Lsec>
                  <Logo src="/images/Tezos.svg" />
                  <Currency>
                    <Symbol>XTZ</Symbol>
                    <Chain>Tezos</Chain>
                  </Currency>
                </Lsec>
                <Block>
                <DataApy>6.00%</DataApy>
                  <Tit>Apy</Tit>
                </Block>
                <Block>
                  <Data>0.00%</Data>
                  <Tit>Commission</Tit>
                </Block>
                <Status>Network is live</Status>
              </StakeTop>
              <Line />
              <StakeCenter>
                <ProfitList>
                  <Profit>年收益：tokenNum * APY * (1-COMMISSION)</Profit>
                  <Profit>月收益：tokenNum * APY * (1-COMMISSION) / 12</Profit>
                  <Profit>日收益：tokenNum * APY * (1-COMMISSION)/ 365</Profit>
                </ProfitList>
                <ProArrow />
                <Example>
                  如果Stake 10,000 Token，那么年收入如下计算： <br/>年收益=10000 *
                  6.00% =600 Token
                </Example>
              </StakeCenter>
              <Line />
              <StakeBottom>
                <h1>{fmt({id:'steps'})}</h1>
                <p>
                  1. Baker Address: tz1LmaFsWRkjr7QMCx5PtV6xTUz3AmEpKQiF
                </p>
                <p>
                  2.<code>tezos-client set delegate for &lt;implicit_account&gt; to tz1LmaFsWRkjr7QMCx5PtV6xTUz3AmEpKQiF</code>
                </p>
              </StakeBottom>
            </StakeWrap>
          </StakeModel>
          <StakeModel className={stake === '3' ? 'show' : ''}>
            <Back onClick={showStakeModel}>Back</Back>
            <StakeWrap>
              <StakeTop>
                <Lsec>
                  <Logo src="/images/ETH.svg" />
                  <Currency>
                    <Symbol>ETH</Symbol>
                    <Chain>ETHEREUM2.0</Chain>
                  </Currency>
                </Lsec>
                <Block>
                  <DataApy>6.10%</DataApy>
                  <Tit>Apy</Tit>
                </Block>
                <Block>
                  <Data>0.00%</Data>
                  <Tit>Commission</Tit>
                </Block>
                <Status>Network is live</Status>
              </StakeTop>
              <Line />
              <StakeCenter>
                <ProfitList>
                  <Profit>年收益：tokenNum * APY * (1-COMMISSION)</Profit>
                  <Profit>月收益：tokenNum * APY * (1-COMMISSION) / 12</Profit>
                  <Profit>日收益：tokenNum * APY * (1-COMMISSION)/ 365</Profit>
                </ProfitList>
                <ProArrow />
                <Example>
                  如果Stake 10,000 Token，那么年收入如下计算： <br/>年收益=10000 * 6.1% =610 Token
                </Example>
              </StakeCenter>
              <Line />
              <StakeBottom>
                <h1>{fmt({id:'steps'})}</h1>
                <p>
                  ETH Address: 0x000000000000000000000000
                </p>
              </StakeBottom>
            </StakeWrap>
          </StakeModel>
        </Plate>
        <DaoBanner>
          <Title>{fmt({id:'DAO_Governance'})}</Title>
          <DaoSubTitle>
            <h2>{fmt({id:'DAO_Governance_text'})}</h2>
          </DaoSubTitle>
        </DaoBanner>
        <Plate>
          <Title>
            {fmt({ id: 'Security_Audits' })}
            <SecuritSubTitle>{fmt({ id: 'Security_Audits_text' })}</SecuritSubTitle>
          </Title>
          
          <PlateList>
            <SecurityItem>
              <a href="https://github.com/dforce-network/documents/blob/master/audit_report/Lending/dForceLending-Audit-Report-TrailofBits-Mar-2021.pdf" target="_blank" rel="noreferrer">
                <img src="/images/Trofabilts.svg" />
              </a>
            </SecurityItem>
            <SecurityItem>
            <a href="https://github.com/dforce-network/documents/blob/master/audit_report/Lending/dForceLending-Audit-Report-Consensys-Mar-2021.pdf" target="_blank" rel="noreferrer">
              <img src="/images/Diligence.svg" />
            </a>
            </SecurityItem>
            <SecurityItem>
            <a href="https://github.com/dforce-network/documents/blob/master/audit_report/Lending/dForceLending-Audit-Report-Certik-Feb-2021.pdf" target="_blank" rel="noreferrer">
              <img src="/images/Certik.png" />
            </a>
            </SecurityItem>
            <SecurityItem>
            <a href="https://github.com/dforce-network/documents/blob/master/audit_report/Lending/dForceLending-Audit-Report-Certora-Feb-2021.pdf" target="_blank" rel="noreferrer">
              <img src="/images/Certora.svg" />
            </a>
            </SecurityItem>
          </PlateList>
        </Plate>
        <Plate>
          <Title>{fmt({id:'Ecosystem'})}</Title>
          <PlateList>
            <DAOItem>
              <DAOIcon src="/images/logo-1.svg" />
              <DAOText>
              {fmt({id:'Ecosystem1'})}
              </DAOText>
              <DAOHref href="https://dforce.network/" target="_blank"/>
            </DAOItem>
            <DAOItem>
              <DAOIcon src="/images/logo-2.svg" />
              <DAOText>
              {fmt({id:'Ecosystem2'})}
              </DAOText>
              <DAOHref href="https://stafi.io/" target="_blank" />
            </DAOItem>
            <DAOItem>
              <DAOIcon src="/images/logo-3.svg" />
              <DAOText>
              {fmt({id:'Ecosystem3'})}
              </DAOText>
              <DAOHref href="https://www.lido.fi" target="_blank" />
            </DAOItem>
            {/* <DAOItem>
              <DAOIcon src="/images/logo-4.svg" />
              <DAOText>
              {fmt({id:'Ecosystem4'})}
              </DAOText>
              <DAOHref href="#" target="_blank" />
            </DAOItem> */}
          </PlateList>
        </Plate>
        <Footer>
          <Content>
            <dl>
              <dt>{fmt({id:'Product'})}</dt>
              <dd>
                <a href="https://app.liqee.io" rel="noreferrer">{fmt({id:'Lending'})}</a>
              </dd>
            </dl>
            <dl>
              <dt>{fmt({id:'Doc'})}</dt>
              <dd>
                <a href="https://docs.liqee.io" target='_blank' rel="noreferrer">{fmt({id:'FAQ'})}</a>
              </dd>
              <dd>
                <a href="https://github.com/Liqee" target='_blank' rel="noreferrer">{fmt({id:'Developers'})}</a>
              </dd>
              <dd>
                <a href="https://docs.liqee.io/developers/audit-reports" target='_blank' rel="noreferrer">{fmt({id:'Audit_Reports'})}</a>
              </dd>
              <dd>
                <a href="https://github.com/Liqee/documents/blob/main/Liqee_Whitepaper.pdf" target='_blank' rel="noreferrer">{fmt({id:'Whitepaper'})}</a>
              </dd>
              
            </dl>
            <dl>
              <dt>{fmt({id:'Governance'})}</dt>
              <dd>
                <a href="https://forum.liqee.io/" target='_blank' rel="noreferrer">{fmt({id:'Forum'})}</a>
              </dd>
              {/* <dd>
                <a href="https://snapshot.org/#/dforce" target='_blank' rel="noreferrer">{fmt({id:'Vote'})}</a>
              </dd> */}
            </dl>
            <dl className="friendLink">
              <dt>{fmt({id:'Community'})}</dt>
              <dd>
                <a href="https://twitter.com/liqeeio" target='_blank' rel="noreferrer">
                  <img
                    src="/images/link/icon-twitter.svg"
                    alt="Liqee Twitter"
                  />
                </a>
                {/* <a href={(locale === 'en') ? "https://t.me/liqee" : "https://t.me/LiqeeChinese"} target='_blank' rel="noreferrer">
                  <img src="/images/link/icon-tele.svg" alt="Liqee Telegram" />
                </a> */}
                <a href="https://liqee.medium.com/" target='_blank' rel="noreferrer">
                  <img src="/images/link/icon-medium.svg" alt="Liqee Medium" />
                </a>
                {/* <a href="https://www.reddit.com/r/dForceNetwork" target='_blank' rel="noreferrer">
                  <img src="/images/link/icon-reddit.svg" alt="Liqee Reddit" />
                </a> */}
                {/* <a href="https://discord.gg/7NREFxeueA" target='_blank' rel="noreferrer">
                  <img
                    src="/images/link/icon-discord.svg"
                    alt="Liqee Discord"
                  />
                </a> */}
                {/* <a href="https://www.youtube.com/channel/UCvvI93uh1tHIc-49eejricQ" target='_blank' rel="noreferrer">
                  <img
                    src="/images/link/icon-youtube.svg"
                    alt="Liqee Youtube"
                  />
                </a> */}
                {/* <a href="https://forum.dforce.network" target='_blank' rel="noreferrer">
                  <img src="/images/link/icon-df.svg" alt="Liqee DF" />
                </a> */}
                {/* <a>
                  <img src="/images/link/icon-wechat.svg" alt="Liqee wechat" />
                  <img
                    className="qrCode"
                    src="/images/link/qrcode.svg"
                    alt="Liqee wechat"
                  />
                </a> */}
                {/* <a href="https://m.weibo.cn/u/5390046311?from=10B8195010&wm=9006_2001&sourceType=weixin" target='_blank' rel="noreferrer">
                  <img src="/images/link/icon-weibo.svg" alt="Liqee weibo" />
                </a> */}
                {/* <a href="https://space.bilibili.com/2088369305" target='_blank' rel="noreferrer">
                  <img
                    src="/images/link/icon-bilibili.svg"
                    alt="Liqee bilibili"
                  />
                </a> */}
              </dd>
            </dl>
          </Content>
        </Footer>
      </Container>
      <Mmenu className={menu ? 'show' : ''}>
        <MtopBar>
          <Log src="/images/logo-m-open.svg" />
          <Menu src="/images/m-close.svg" onClick={showMenu} />
        </MtopBar>
        <Mnav onClick={showMenu}>
          {/* <li>
                <a href="#" rel="noreferrer">{fmt({ id: 'Lending' })}</a>
              </li> */}
              {/* <li>
              <a href="#" target='_blank'>{fmt({ id: 'Tokenization' })}</a>
              </li>
              <li>
              <a href="#" target='_blank'>{fmt({ id: 'Staking' })}</a>
              </li> */}
              <li>
              <a href="https://docs.liqee.io/" target="_blank" rel="noreferrer">{fmt({ id: 'Documents' })}</a>
              </li>
          <li onClick={() => changeLanguage('en')}>
            <img src="/images/EN.svg" />
            <span>English</span>
          </li>
          <li onClick={() => changeLanguage('cn')}>
            <img src="/images/CN.svg" />
            <span>简体中文</span>
          </li>
        </Mnav>
      </Mmenu>
    </>
  );
};
export default Home;

const Container = styled.div`
  width: 1920px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  @media (max-width: 1200px) {
    width: 100vw;
    position: inherit;
    left: 0;
    transform: translateX(0);
  }
`;
const Mheader = styled.section`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 470px;
    background: url('/images/m-bg-top.svg') no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
  }
`;
const MtopBar = styled.header`
  @media (max-width: 1200px) {
    width: 100%;
    height: 50px;
    min-height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
`;
const Menu = styled.img`
  width: 18px;
  height: 18px;
`;
const Mnav = styled.nav`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: #eceff2;
  padding-top: 1px;
  li {
    font-size: 16px; 
    line-height: 45px;
    background-color: #fff;
    position: relative;
    padding: 0 15px 0 15px;
    margin-bottom: 12px;
    display: flex;
    /* &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 12px;
      background: #eda039;
      border-radius: 2px;
      left: 15px;
      top: 16px;
    } */
    a {
      display: block;
      color: #242a47;
      width: 100%;
      height: 100%;
    }
    img {
      margin-right: 5px;
    }
  }
`;
const Mmenu = styled.section`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #fff;
  color: #000;
  &.show {
    display: flex;
    flex-direction: column;
  }
`;
const Header = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 806px;
  background: url('/images/bg-top.svg') no-repeat;
  background-size: 1920px 807px;
  color: #fff;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const TopBar = styled.header`
  width: 1200px;
  height: 90px;
  line-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Log = styled.img`
  width: 133px;
  height: 51px;
  @media (max-width: 1200px) {
    width: 66px;
    height: 25px;
  }
`;
const ChangeLanguage = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  img {
    width: 24px;
    height: 16px;
    margin-right: 6px;
  }
  span {
    margin-right: 6px;
  }
  b {
    display: block;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #fff;
  }
`;
const Changelist = styled.ul`
  /* display:none; */
  &.hide {
    display: none;
  }
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0 20px;
  border-radius: 6px;
  li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    height: 45px;
    line-height: 45px;
    margin: 0 !important;
    img {
      width: 24px;
      height: 16px;
      margin-right: 6px;
    }
    span {
      font-size: 14px;
      margin-right: 6px;
      color: #080D27;
    }
    &:hover {
      span {
        color: #000;
      }
    }
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  li {
    margin-left: 50px;
    a {
      color: #fff;
    }
  }
`;
const Slogan = styled.article`
  width: 1200px;
  height: 264px;
  display: flex;
  justify-content: space-between;
  margin-top: 122px;
  @media (max-width: 1200px) {
    width:100%;
    padding:0 15px;
    flex-direction:column;
    margin:20px 0 10px;
  }
`;
const SloLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 480px;
  h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 66px;
  }
  h3 {
    font-size: 20px;
    line-height: 30px;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 192px;
    height: 54px;
    cursor: pointer;
    background: linear-gradient(270deg, #ed726b 0%, #ffa05e 100%);
    border-radius: 27px;
    img {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }
  };
  @media (max-width: 1200px) {
    h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 33px;
      margin-bottom:10px;
    }
    h3 {
      font-size: 14px;
      line-height: 23px;
      margin-bottom:20px;
    }
    a {
      width: max-content;
      height: 27px;
      min-height:27px;
      border-radius: 27px;
      font-size:12px;
      padding:0 17px;
      margin-bottom:25px;
      img {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 10px;
      }
    };
  }
`;
const SloDate = styled.div`
  display: flex;
  /* flex-direction:column; */
  justify-content: space-between;
  align-content: space-between;
  width: 654px;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    width:100%;
    /* flex-direction:column */
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 125px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  h6 {
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
  }
  p {
    font-size: 24px;
    font-weight:600;
    line-height: 29px;
  };
  @media (max-width: 1200px) {
    width:49%;
    height:auto;
    padding:10px;
    margin-bottom:8px;
    h6 {
      font-size: 12px;
      line-height: 19px;
      opacity: 0.6;
    }
    p {
      font-size: 14px;
      line-height: 29px;
    };
  }
`;
const Plate = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  margin-bottom: 100px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
`;
const Title = styled.h1`
  font-size: 44px;
  font-weight: 600;
  color: #080d27;
  line-height: 54px;
  margin-bottom: 60px;
  text-align:center;
  @media (max-width: 1200px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 28px;
  }
`;
const PlateList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  align-items:center;
  width: 100%;
  &.hide {
    display: none;
  }
`;
const HowItem = styled.li`
  width: 386px;
  height: 250px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(244, 245, 246, 0.8);
  line-height: 24px;
  img {
    width: 50px;
    height: 50px;
    margin-bottom:25px;
  }
  h6 {
    font-size: 20px;
    font-weight:600;
    color: #080d27;
    margin-bottom:11px;
  }
  p {
    font-size: 14px;
    color: #4f5265;
    margin-bottom:17px;
  }
  a {
    display: block;
    width: 26px;
    height: 26px;
    padding: 20px;
    margin: -20px -20px 0 0;
    align-self: flex-end;
    background: url('/images/how-arrive.svg') no-repeat;
    background-size: 20px 20px;
    background-position: center bottom;
    &.hide{
      opacity:0;
      cursor:inherit;
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
const LendItem = styled.li`
  width: 286px;
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #f3f3f7;
  margin-bottom: 19px;
  img {
    width: 90px;
    height: 90px;
  }
  p {
    font-size: 16px;
    color: #080d27;
    line-height: 19px;
  }
  @media (max-width: 1200px) {
    width: 48%;
    height: 133px;
    padding: 23px 0;
    margin-bottom: 10px;
    img {
      width: 53px;
      height: 53px;
    }
    p {
      font-size: 14px;
      line-height: 15px;
    }
  }
`;
const TokenizeItem = styled.li`
  img {
    width: 440px;
    height: 330px;
  }
  dl {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
    height: auto;
    color:#4F5265;
    dd {
      position: relative;
      line-height: 28px;
      background-color:#F4F5F6;
      padding:20px 30px;
      border-radius:6px;
      a{
        color:#384ddc;
      }
    }
    dt {
      background-color:#F4F5F6;
      padding:20px 30px;
      border-radius:6px;
      margin:10px 0;
      h2{
        font-size:18px;
        font-weight:600;
        color:#080D27;
      }
      ul{
        li{
          list-style: disc;
          font-size:14px;
          margin: 15px 0 15px 17px;
          line-height: 15px;
          a{
            color:#384ddc;
            text-decoration:underline
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    margin: 0 auto;
    img {
      width: 251px;
      height: 187px;
      margin-bottom: 31px;
    }
    dl {
      width: 100%;
      height:auto;
      dt {
        margin:10px 0;
        ul{
        li{
          list-style: disc;
          font-size:14px;
          margin: 5px 0 5px 17px;
          line-height: 15px;
        }
      }
      };
      dd{
        font-size:12px;
        line-height:20px;
      }
    }
  }
`;
const Status = styled.p`
  font-size: 14px !important;
  font-weight:400!important;
  color: #4f5265;
  line-height: 17px;
  margin: 0 !important;
  position: relative;
  padding-left: 15px;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #44d772;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
`;
const StakeItem = styled.li`
  width: 386px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 30px 0;
  border-radius: 6px;
  border: 1px solid #f3f3f7;
  margin-bottom: 19px;
  cursor: pointer;
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
  p {
    font-size: 22px;
    font-weight:600;
    color: #10152e;
    line-height: 26px;
    margin-bottom: 18px;
  }
  span {
    display: inline-block;
    font-size: 14px;
    color: #4f5265;
    line-height: 17px;
    b {
      color: #23d266;
    }
  }
  &.disabled {
    background: #f4f5f6;
    cursor: not-allowed;
    pointer-events: none;
    ${Status} {
      &:before {
        background: #ffac39;
      }
    }
    span {
      color: #384ddc;
    }
  }
  @media (max-width: 1200px) {
    width:100%
  }
`;
const StakeModel = styled.section`
  display: none;
  width: 1200px;
  color:#4F5265;
  &.show {
    display: flex;
    flex-direction: column;
  };
  @media (max-width: 1200px) {
      width:100%
  }
`;
const Back = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  height: 35px;
  line-height: 35px;
  color: rgb(145, 149, 164);
  margin-left: 10px;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-right: 2px solid rgb(145, 149, 164);
    border-top: 2px solid rgb(145, 149, 164);
    transform: rotate(-135deg);
  };
  @media (max-width: 1200px) {
    margin-left:0;
    font-size:14px;
  }
`;
const StakeWrap = styled.section`
  /* border: 1px solid rgb(145, 149, 164); */
  box-shadow: 0px 0px 15px 0px #E1E1E1;
  border-radius: 6px;
  min-height:470px;
`;
const StakeTop = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 20px 30px;
  ${Status}{
    margin-left:210px!important;
  }
  @media (max-width: 1200px) {
    height:auto;
    ${Status}{
      margin-left:0!important;
    }
  }
`;
const Lsec = styled.div`
  display: flex;
  justify-content: inherit;
`;
const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  @media (max-width: 1200px) {
    width:30px;
    height:30px;
    margin-right: 10px;
  }
`;
const Currency = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Symbol = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color:#10152E;
`;
const Chain = styled.div`
  font-size: 14px;
  line-height: 20px;
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Tit = styled.div`
  font-size: 14px;
  line-height: 20px;
`;
const DataApy = styled.div`
font-size: 16px;
font-weight: 600;
line-height: 30px;
color:#23D266;
`;

const Data = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  color:#10152E;
`;
const Line = styled.div`
  width: calc(100% - 60px);
  height: 0;
  border-bottom:1px solid #f3f3f7;
  margin:0 auto;
`;
const StakeCenter = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 20px 30px;
  @media (max-width: 1200px) {
    flex-direction:column;
    height:auto
  }
`;
const ProfitList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Profit = styled.div`
  line-height: 30px;
`;
const ProArrow = styled.div`
  width:45px;
  height:13px;
  background: url('/images/proArrow.svg') no-repeat;
  background-size:cover;
`;
const Example = styled.p`
  max-width: 500px;
  line-height: 35px;
  background: #F4F5FD;
  border-radius: 6px;
  padding:10px 30px;
`;
const StakeBottom = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  h1{
    font-size: 16px;
    font-weight: 600;
    color: #10152E;
    margin-bottom:8px;
  }
  p{
    margin-bottom:20px;
    line-height:25px;
    a{
      color:#2440b3
    }
  }
  @media (max-width: 1200px) {
    flex-direction:column;
    height:auto
  }
`;
const DaoBanner = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 532px;
  padding-top: 60px;
  background: url('/images/bg-dao.png') no-repeat;
  background-size: 1920px 532px;
  color: #fff;
  margin-bottom: 80px;
  ${Title} {
    color: #fff;
  }
  @media (max-width: 1200px) {
    height: 228px;
    padding-top: 25px;
    background-size: cover;
    background-position:center;
    margin-bottom: 30px;
  }
`;
const DaoSubTitle = styled.article`
  text-align: center;
  h2 {
    font-size: 20px;
    line-height: 40px;
    max-width:860px;
  }
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    h2 {
      font-size: 14px;
      line-height: 20px;
      margin-top: -20px;
      max-width:80%
    }
  }
`;
// SecurityItem
const SecurityItem = styled.li`
  width:286px;
  height:140px;
  display:flex;
  justify-content:center;
  align-items:center;
  background:rgba(244, 245, 246, 0.8);
  border-radius: 6px;
  @media (max-width: 1200px) {
    width:100%;
    height:140px;
    margin-bottom:19px;
  }
`
const SecuritSubTitle = styled.h3`
  font-size: 20px;
  margin-top:20px;
  color: #4F5265;
  @media (max-width: 1200px) {
    font-size:14px;
    max-width:85%;
    margin: 10px auto 0;
    line-height: 18px;
  }
`

// Ecosystem
const DAOItem = styled.li`
  width: 100%;
  height: 140px;
  padding: 45px 30px 45px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #f3f3f7;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
    height: 124px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
`;
const DAOIcon = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: 1200px) {
    width: 25px;
    height: 25px;
    margin-right:5px;
  }
`;
const DAOText = styled.p`
  display: flex;
  align-content: center;
  line-height:24px;
  font-size: 16px;
  font-weight: 400;
  color: #4f5265;
  max-width: 864px;
  @media (max-width: 1200px) {
    font-size: 12px;
    max-width: 90%;
    line-height:18px;
  }
`;
const DAOHref = styled.a`
  display: block;
  width: 38px;
  height: 38px;
  padding: 30px;
  background: url('/images/arrive.svg') no-repeat;
  background-size: 38px 38px;
  background-position: center;
  @media (max-width: 1200px) {
    width: 23px;
    height: 23px;
    background-size: 23px 23px;
    margin: 0 -20px 0 -15px;
  }
`;
const Footer = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #080d27;
  @media (max-width: 1200px) {
    width: 100vw;
  }
`;
const Content = styled.section`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  width: 1200px;
  padding: 70px 0 100px;
  dl {
    display: flex;
    flex-direction: column;
    line-height: 38px;
    color: #fff;
  }
  dt {
    font-size: 20px;
  }
  dd {
    font-size: 14px;
    opacity: 0.7;
  }
  .friendLink {
    dd {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      margin: 20px -50px 0 0;
      width: 140px;
      height: 60px;
      a {
        display: block;
        position: relative;
        margin-right: 50px;
        img {
          width: 16px;
          height: 16px;
        }
        .qrCode {
          width: 120px;
          height: 120px;
          display: none;
          position: absolute;
          left: 50%;
          top: -130px;
          transform: translateX(-50%);
        }
        &:hover {
          .qrCode {
            display: block;
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 30px 15px;
    flex-wrap: wrap;
    dl {
      width: 31%;
      line-height: 25px;
    }
    dt {
      font-size: 14px;
    }
    dd {
      font-size: 12px;
    }
    .friendLink {
      margin-top: 28px;
      dd {
        margin: 25px -35px 0 0;
        width: 110px;
        a {
          margin-right: 35px;
        }
      }
    }
  }
`;
